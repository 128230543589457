import {AnyObject} from "@/types/main";

export default interface SchoolItiParameters {
    TRANSPORT_RULES_LINK: string,
    CB_DELAYED_PAYMENT_DEAD_LINE: Date,
    BILLING_PAYMENT_PERIODS: BillingPaymentPeriods[],
    SUPPORT_LINK: string,
    DUPLICATE_PAPER_LINK?: string,
    LEGAL_MENTION_SUBSCRIPTION_FORM: string,
    SCHOLAR_YEAR: number,
    TAX_NOTICE_YEAR: number, // calculated
    SUBSCRIPTIONS_OPEN: boolean,
    REG_INF_PHOTO_MANDATORY: boolean,
    REG_INF_MIN_BIRTH_DATE: Date | null,
    REG_INF_FOSTER_FAMILY: boolean,
    REG_INF_REFUGEE_CLAIMANT: boolean,
    REG_INF_REVIEW_REQUEST: boolean,
    VERIFY_CONTACT_EMAIL: boolean,
    VERIFY_CONTACT_PHONE: boolean,
    PREVIOUS_YEAR_ASSIGNMENT_END_SUBS_DATE: Date | undefined,
    AI_URL_RGPD_PROTECTION_POLICY: string,
    REG_INF_SUGGEST_PHONE_TRAVELER_INFORMATION: boolean,
    REG_INF_SUGGEST_EMAIL_ACTUALITY: boolean,
    REG_INF_RGPD_POLITIC: string,
    REG_INF_WELCOME_INFORMATION: string,
    AI_NAME: string,
    REG_INF_DISPLAY_FARE: boolean,
    REG_INF_PHOTO_RESTRICT_CONFORMITY: boolean,
    REG_DROP_WISH_NON_BLOCKING: boolean,
    REG_DROP_IN_CENTER_ALLOW_ONLINE_REGISTRATION: boolean,
    REG_INF_STEP_1_HELP: string,
    REG_INF_STEP_2_HELP: string,
    REG_INF_STEP_3_HELP: string,
    REG_INF_TICKETING_EXTERNAL_NUMBER_ENABLED: boolean,
    REG_INF_TICKETING_EXTERNAL_NUMBER_MESSAGE: string,
    REG_INF_MAP_LAT_LNG_ZOOM: string,
    REG_INF_TOOLTIP_IMAGE: string,
    REG_INF_LOGO_IMAGE: string,
    REG_INF_LR_BIRTH_DATE_REQUIRED: boolean,
    REG_INF_COMMENT: boolean,
    REG_MANAGE_FAMILY_RATIO: boolean,
    REG_INF_LR2_WISH_MANAGEMENT: boolean,
    REG_SUBSCRIPTION_DURING_SCHOOL_YEAR: boolean,

    // Calculated
    LOGO_IMAGE_URL?: string,
    TOOLTIP_IMAGE_URL?: string,
    HAS_TREASURY_ORMC: boolean,
}

export class BillingPaymentPeriods {
    periodic: string;
    dueDates: Array<Date | null>;
    deadline: Date | null;


    constructor(periodic: string, dueDates: Array<Date | null>, deadline: Date | null) {
        this.periodic = periodic;
        this.dueDates = dueDates;
        this.deadline = deadline;
    }

    static parse(str: string): BillingPaymentPeriods[] {
        const parsed: Array<AnyObject> = JSON.parse(str);

        return parsed.map(billingPeriod => new BillingPaymentPeriods(
            billingPeriod.periodic,
            billingPeriod.dueDates.map(dueDate => dueDate ? new Date(dueDate) : null),
            billingPeriod.deadline ? new Date(billingPeriod.deadline) : null
        ));
    }
}
